import APODContent from "./APODContent"

const IndexRoute = () => {
    return (
      <>
        <APODContent />
      </>
    )
}

export default IndexRoute